import { getResponseData } from "../../middleware/middleware";
import {
  FETCH_DASHBOARDUSERINFO_DATA,
  FETCH_DASHBOARDUSERINFO_DATA_SUCCESS,
  FETCH_DASHBOARDUSERINFO_DATA_FAILURE,
  LOGOUT_USERINFO_DATA,
  LOGOUT_USERINFO_DATA_SUCCESS,
  LOGOUT_USERINFO_DATA_FAILURE,
  RESET_USERINFO_DATA,
} from "./types";

import{RESET_DATA} from "../types"

import {
  getSelectedStylist,
  getTimeSlotData,
  getSelectedDate,
  isGeolocationEnabled,
  selectedStoreData,
  getServiceData,
  fetchFailedConfirmationDate,
  currentBookingStateUpdate,
} from "../appointments/appointments";

import { updateUserLocationDataSucess } from "../location/updateUserLocation";
import {fetchUpcomingApptsDataSuccess} from  "../Dashboard/upcomingAppointments";
import { removeCategoryData } from "../category/category";
import { removeAuthenticationData } from "../authentication/authentication";
import { removeStylistcard } from "../stylist/stylist";
import { fetchReserveAppointmentnDataSucess } from '../appointments/reserveappointment'
import {persistor } from "../../store";
import { deleteApptSoftBookiData } from "../appointments/deleteApptSoftBooking";

export const fetchDashboardData = (data) => {
  return {
    type: FETCH_DASHBOARDUSERINFO_DATA,
    data,
  };
};

export const fetchDashboardDataSucess = (data) => {
  return {
    type: FETCH_DASHBOARDUSERINFO_DATA_SUCCESS,
    data,
  };
};

export const fetchDashboardDataFailure = () => {
  return {
    type: FETCH_DASHBOARDUSERINFO_DATA_FAILURE,
  };
};

export const removeAllStoreData = () => async (dispatch) => {
  dispatch(getSelectedStylist(null));
  dispatch(getTimeSlotData(null));
  dispatch(getSelectedDate(null));
  dispatch(isGeolocationEnabled(false));
  dispatch(selectedStoreData(null));
  dispatch(getServiceData(null));
  dispatch(removeCategoryData());
  dispatch(removeAuthenticationData());
  dispatch(removeStylistcard());
  dispatch(fetchDashboardDataSucess(null));
  dispatch(updateUserLocationDataSucess(null));
  dispatch(fetchUpcomingApptsDataSuccess(null));
  dispatch(fetchFailedConfirmationDate(null));
  dispatch(fetchReserveAppointmentnDataSucess(null, false));
  dispatch(currentBookingStateUpdate(null));
  dispatch(deleteApptSoftBookiData(false));
};
export const getLogoutInfoData = () => async (dispatch) => {
  dispatch(fetchLogoutData(true));
  const payload = {
    method: "POST",
    url: `/api/session/v1/logout`,
  };
  const getLogoutData = await getResponseData(payload);
  if (getLogoutData?.status === 200) {
    dispatch(fetchLogoutData(false));
    dispatch(fetchLogoutDataSuccess(getLogoutData?.data.response));
    dispatch(removeAllStoreData());
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    sessionStorage.clear();
  } else {
    dispatch(fetchLogoutDataFailure(getLogoutData?.error));
  }
};

export const getDashboardUserInfoData = () => async (dispatch) => {
  dispatch(fetchDashboardData(true));
  const payload = {
    method: "GET",
    url: `/api/session/v1/userInfo`,
  };
  const getUserInfodata = await getResponseData(payload);
  if (getUserInfodata?.status === 200) {
    dispatch(fetchDashboardData(false));
    dispatch(fetchLogoutDataSuccess(null));
    dispatch(fetchDashboardDataSucess(getUserInfodata?.data));
  } else {
    dispatch(fetchDashboardDataFailure(getUserInfodata?.error));
  }
};

//   Logout  //

export const fetchLogoutData = (data) => {
  return {
    type: LOGOUT_USERINFO_DATA,
    data,
  };
};

export const fetchLogoutDataSuccess = (data) => {
  return {
    type: LOGOUT_USERINFO_DATA_SUCCESS,
    data,
  };
};

export const fetchLogoutDataFailure = () => {
  return {
    type: LOGOUT_USERINFO_DATA_FAILURE,
  };
};
export const resetUserInfoData = () => {
  return {
    type: RESET_USERINFO_DATA,
  }
}

export const removeAppointmentCardData = () => async (dispatch) => {
  dispatch({type: RESET_DATA})
};
