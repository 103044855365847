import {
    FETCH_STYLIST_CARD_DATA_FOR_STYLIST_FLOW_REQUEST,
     FETCH_STYLIST_CARD_DATA_FOR_STYLIST_FLOW_SUCCESS,
    FETCH_STYLIST_CARD_DATA_FOR_STYLIST_FLOW_FAILURE,
    SET_CURRENT_STYLIST,
    RESET_DATA_SWITCH_FLOW
  } from "../actions/stylistFlow/types";
  import {RESET_DATA} from "../actions/types";

  /**
 @typedef initialState
 @type {Object}
 @property {String} flow which flow user currently in.
 @property {Object[]} stylistFlowData list of stylist.
 @property {boolean} loading is the stylist loading.
 @property {boolean} hasErrors is error occur.
 @property {boolean} hasErrors are errors present.
 @property {string} maxStylist max stylist user can select.
 @property {string} maxService max service user can select.
 @property {string} currentStylist stylit on appointment card if no service selected.
 @property {string} error specific error.
 */
  const initialState = {
    flow:null,
    stylistFlowData: null,
    loading: false,
    hasErrors: false,
    maxStylist:window.APP_CONFIG.REACT_APP_MAX_STYLIST?window.APP_CONFIG.REACT_APP_MAX_STYLIST:2,
    maxService:window.APP_CONFIG.REACT_APP_MAX_SERVICE?window.APP_CONFIG.REACT_APP_MAX_SERVICE:5,
    currentStylist:null,
    error: null
  };

  export default function stylistFlowReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_STYLIST: 
          return {
            ...state,
            currentStylist: action.data,
          };
        case FETCH_STYLIST_CARD_DATA_FOR_STYLIST_FLOW_REQUEST :
          return {
            ...state,
            hasErrors: false,
            loading: true
          };
          case FETCH_STYLIST_CARD_DATA_FOR_STYLIST_FLOW_SUCCESS:
            return {
              ...state,
              loading: false,
              stylistFlowData: action.data,
              flow:action.data?"Stylist":null,
              hasErrors: false,
            };
          case FETCH_STYLIST_CARD_DATA_FOR_STYLIST_FLOW_FAILURE:
            return {
              ...state,
              loading: false,
              hasErrors: true,
              flow:action.data?"Stylist":null,
              error: action.error,
            };
        case RESET_DATA_SWITCH_FLOW:
        return {...initialState};
          case RESET_DATA:
          return {...initialState}
      default:
        return state;
    }
  }
