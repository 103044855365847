import {
  FETCH_DASHBOARDUSERINFO_DATA,
  FETCH_DASHBOARDUSERINFO_DATA_SUCCESS,
  FETCH_DASHBOARDUSERINFO_DATA_FAILURE,
  LOGOUT_USERINFO_DATA,
  LOGOUT_USERINFO_DATA_SUCCESS,
  LOGOUT_USERINFO_DATA_FAILURE,
  RESET_USERINFO_DATA,
} from "../actions/Dashboard/types";

const initialState = {
  usrInfo: null,
  loading: false,
  hasErrors: false,
  logout: null,
};

export default function dashboardFlyoutReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DASHBOARDUSERINFO_DATA:
      return {
        ...state,
        loading: action.data,
      };
    case FETCH_DASHBOARDUSERINFO_DATA_SUCCESS:
      return {
        ...state,
        usrInfo: action.data,
      };
    case FETCH_DASHBOARDUSERINFO_DATA_FAILURE:
      return {
        ...state,
        hasErrors: action.data,
      };

    case LOGOUT_USERINFO_DATA:
      return {
        ...state,
        loading: action.data,
      };
    case LOGOUT_USERINFO_DATA_SUCCESS:
      return {
        ...state,
        logout: action.data,
        
      };
    case LOGOUT_USERINFO_DATA_FAILURE:
      return {
        ...state,
        hasErrors: action.data,
      };
      case RESET_USERINFO_DATA:
        return {
          ...state,
          usrInfo: null,
        }
    default:
      return state;
  }
}
