import React from 'react';
import PropTypes from "prop-types";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Divider from "web-common-ssr/src/views/components/Divider/Divider";
/* eslint-disable react-hooks/rules-of-hooks */
import { useDeviceInflection } from "web-common-ssr/src/views/__core/InflectionProvider/InflectionProvider";
import "./Layout.scss";

const Layout = (props) => {
  const { hederObj, footerObj } = props;
  const { breakpoint } = useDeviceInflection();
  const isSmallDevice = breakpoint?.isSmallDevice();
  return (
    <div className="page-containe">
      {hederObj?.isHeader && <Header {...{ hederObj }} />}

      <Divider type={"gray"}> </Divider>
      <div className={`${props?.classes || "MainContainer"}`}>
        {props.children}
      </div>
      {footerObj?.isFooter && !isSmallDevice && (
        <Footer
          spacerValue="00"
          id="contentId"
          {...{
            footerObj,
          }}
        />
      )}
      {footerObj?.isFooter &&
        isSmallDevice &&
        footerObj?.showAppointmentCardFooter && (
          <Footer
            spacerValue="00"
            id="contentId"
            {...{
              footerObj,
            }}
          />
        )}
    </div>
  );
};

/**
 * Property type definitions
 * @property {string} hederObj - Sets the image
 * @property {string} ImageAlt - Sets the ImageAlt
 */

Layout.propTypes = {
  hederObj: PropTypes.object,
  isFooter: PropTypes.object,
};

export default Layout;
